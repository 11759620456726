import { environment } from '../../environments/environment';
import { LogisticWarehouseService } from './warehouse/logistic-warehouse.service';

/*//Logistic Warehouse URL api configuration//*/
// 0 => QA, 1 => qa, 2 => PROD
const logisticWarehouseService = new LogisticWarehouseService(2); 
const logisticWarehouseBaseUrl = logisticWarehouseService.getApiUrlFull();
const apiReferenceName = logisticWarehouseService.getApiReferenceName();
const apiUrl = logisticWarehouseService.getApiUrl();
//console.table({logisticWarehouseBaseUrl, apiReferenceName, apiUrl});
/*///////////////////////////////////////////*/

const DevUrl = {
 baseUrl: 'https://go.qa.mardom.com/api/gateway/trackandtrace',
  altBaseUrl: 'https://go.qa.mardom.com/api',
  altBaseUrl2: 'https://go.qa.mardom.com/api',
  filesUrl: 'https://go.qa.mardom.com/api/storage/file',
  trackAndTraceBaseUrl: 'https://go.qa.mardom.com/api/tracking',
  customsFilesUrl: 'https://go.qa.mardom.com',
  manifestBaseUrl:  'https://go.qa.mardom.com/api/manifest-services',
  azualApprovedUrl: "https://go.qa.mardom.com/transferlist?ApprovedUrl",
  azualDeclinedUrl: "https://go.qa.mardom.com/transferlist?DeclinedUrl",
  azualCancelUrl: "https://go.qa.mardom.com/transferlist?CancelUrl",
  logisticWarehouseBaseUrl:logisticWarehouseBaseUrl,
  logisticWarehouseQueryBaseUrl:'https://go.qa.mardom.com/api/logistic-warehouse-query',
  logisticWarehouseWareHouseEntry: 'https://go.qa.mardom.com/api/logistic-warehouse'
}
const serviceConfig = {
  baseUrl: environment.Endpoint.baseUrl.indexOf('#{') > -1 ? DevUrl.baseUrl : environment.Endpoint.baseUrl,
  altBaseUrl: environment.Endpoint.altBaseUrl.indexOf('#{') > -1 ? DevUrl.altBaseUrl : environment.Endpoint.altBaseUrl,
  altBaseUrl2: DevUrl.altBaseUrl2,
  filesUrl: environment.Endpoint.filesUrl.indexOf('#{') > -1 ? DevUrl.filesUrl : environment.Endpoint.filesUrl,
  trackAndTraceBaseUrl: environment.Endpoint.trackAndTraceBaseUrl.indexOf('#{') > -1 ? DevUrl.trackAndTraceBaseUrl : environment.Endpoint.trackAndTraceBaseUrl,
  customsFilesUrl: environment.Endpoint.customsFilesUrl.indexOf('#{') > -1 ? DevUrl.customsFilesUrl : environment.Endpoint.customsFilesUrl,
  manifestBaseUrl: environment.Endpoint.manifestBaseUrl.indexOf('#{') > -1 ? DevUrl.manifestBaseUrl : environment.Endpoint.manifestBaseUrl,
  azualApprovedUrl: environment.Endpoint.azualApprovedUrl.indexOf('#{') > -1 ? DevUrl.azualApprovedUrl : environment.Endpoint.azualApprovedUrl,
  azualDeclinedUrl: environment.Endpoint.azualDeclinedUrl.indexOf('#{') > -1 ? DevUrl.azualDeclinedUrl : environment.Endpoint.azualDeclinedUrl,
  azualCancelUrl: environment.Endpoint.azualCancelUrl.indexOf('#{') > -1 ? DevUrl.azualCancelUrl : environment.Endpoint.azualCancelUrl,
  logisticWarehouseBaseUrl: environment.Endpoint.logisticWarehouseBaseUrl.indexOf('#{') > -1 ? DevUrl.logisticWarehouseBaseUrl : environment.Endpoint.logisticWarehouseBaseUrl,
  logisticWarehouseQueryBaseUrl: environment.Endpoint.logisticWarehouseQueryBaseUrl.indexOf('#{') > -1 ? DevUrl.logisticWarehouseQueryBaseUrl : environment.Endpoint.logisticWarehouseQueryBaseUrl,
  logisticWarehouseWareHouseEntry: DevUrl.logisticWarehouseWareHouseEntry,
  authKey: 'sXtd0amINK017ZGEaaE6j8k9RTzF62Tw',
  endPoints: {
    log: '/general-information/audit',
    booking: '/Bookings',
    customs: '/customManagement',
    outboxMessage: '/OutboxMessage',
    contact: '/Contacts',
    request: '/RequestService',
    transport: '/TransportService',
    voucher: '/TransferVoucher',
    billVoucher: '/BillVoucher',
    customTypesByType: '/CustomsManagementType',
    packingTypes: '/Packingtype',
    ports: '/general-information/port',
    cargotype: '/cargotype',
    EquipmentType: '/general-information/transportequipment',
    wareHouseProducts: '/logistic-warehouse-query/Products/GetProductsByClient',
    wareHouseStocks: '/logistic-warehouse-query/Stock/GetStockByIdClients',
    responsibleCustomer: '/responsible-management/ResponsibleCustomer',
    responsible: '/account-management/EmployeesAccount/GetAllCordinator',
    responsibleLine: '/responsible-management/ResponsibleRequestType/ByLineId/',
    responsibleRequestTypeCustomer:'/responsible-management/ResponsibleCustomer/ResponsibleRequestTypeCustomer/',
    //  user: '/account-management/user/getUserCompanies',
    //user: '/logistic-warehouse/User/GetCompanyList', //FOR PROD ENV
    user: `/${apiReferenceName}/User/GetCompanyList`, //FOR qa ENV
    assignUser: '/account-management/user/getUserCompanies',
    country: '/general-Information/country',
    companySearch: '/account-management/user/getCompaniesStartingWith?name=',
    companyAdd: "/logistic-warehouse/User/add-company-request", //FOR PROD ENV
    //companyAdd: `/${apiReferenceName}/User/add-company-request`, //FOR qa ENV
    userDetails: "/logistic-warehouse/User/updateUserProfie", //FOR PROD ENV
    //userDetails: `/${apiReferenceName}/User/updateUserProfie`, //FOR qa ENV
    getUserProfile: "/logistic-warehouse/User/GetUserProfie", //FOR PROD ENV
    //getUserProfile: `/${apiReferenceName}/User/GetUserProfie`, //FOR qa ENV
    addressAdd: "/logistic-warehouse/User/SaveUserAddress", //FOR PROD ENV
    //addressAdd: `/${apiReferenceName}/User/SaveUserAddress`, //FOR qa ENV
    getUserAddress: "/logistic-warehouse/User/GetUserAddress", //FOR PROD ENV
    //getUserAddress: `/${apiReferenceName}/User/GetUserAddress`, //FOR qa ENV
    deleteAddress: '/logistic-warehouse/User/DeleteUserAddress?Id=', //FOR PROD ENV
    //deleteAddress: `/${apiReferenceName}/User/DeleteUserAddress?Id=`, //FOR qa ENV
    updateAddress: '/logistic-warehouse/User/EditUserAddress', //FOR PROD ENV
    //updateAddress: `/${apiReferenceName}/User/EditUserAddress`, //FOR qa ENV
    customers: '/account-management/Client',
    agents: '/logistic-warehouse-query/Agents/GetByIdClients',
    getAgents: '/logistic-warehouse-query/Agents/GetAgents',
    changeStatus: '/Miscelaneos',
    orderEntry: '/OrderEntry',
    orderDetail: '/OrderDetail',
    referenceDetail: '/ReferenceDetail',
    receptionReference: '/ReceptionReference',
    wareHouseReference: '/logistic-warehouse/ReferenceReception', //FOR PROD ENV
    //wareHouseReference: `/${apiReferenceName}/ReferenceReception`, //FOR qa ENV
    wareHouseOrder: '/logistic-warehouse/Purchase', //FOR PROD ENV
    //wareHouseOrder: `/${apiReferenceName}/Purchase`, //FOR qa ENV
    trackBL: '/BL',
    pendingInvoicebyBl: '/invoice-api/invoices/getBlInvoices',
    pendingInvoice: '/invoice-api/invoices',
    pendingInvoiceDownload: '/invoice-api/invoices/invoicesdownload',
    downloadInvoiceId: '/mardomgo-reports/InvoiceReport',
    downloadInvoiceFF: '/mardomgo-reports/InvoiceReport/FreighForward',
    downloadInvoiceDynamic: '/mardomgo-reports/InvoiceReport/Dynamic',
    warehouseTHByTally: '/desconsolidation-warehouse/BillOfLading/GetByTally',
    warehouseTHAll: '/desconsolidation-warehouse/BillOfLading/',
    rates: '/general-information/dollarrate',
    warehouseTHByBL: '/desconsolidation-warehouse/BillOfLading/GetByNumber',
    warehouseTHQuotation: '/desconsolidation-warehouse/Quotation',
    downloadInvoiceStorageQuote: '/mardomgo-reports/InvoiceReport/ReportStorageQuoteTH',
    azureUpload: '/upload',
    azureDownload: '',
    serviceFiles: '/ServiceFiles',
    traceByPO: '/purchaseorder',
    traceByBL: '/windip/getbybl',
    traceByContainer: '/container',
    BillVoucher: '/BillVoucher',
    ships: '/general-information/ship',
    employeesAccount: '/account-management/EmployeesAccount',
    trackingLasts: '/windip/getbycustomer',
    fileNumber: '/expediente',
    salesexecutive: '/general-information/SalesExecutive',
    tmsServiceType: '/transport-service/TMSTransportRequest/services',
    tmsShipLine: '/transport-service/TMSTransportRequest/shiplines',
    transportRequestSave: '/transport-service/TransportRequest',
    tmsEquipment: '/transport-service/TMSTransportRequest/equipments',
    tmsEquipmentType: '/transport-service/TMSTransportRequest/equipmenttypes',
    tmsLocations: '/transport-service/TMSTransportRequest/freightlocations',
    verifyManifest: '/Manifest/CheckManifiest?ManifestNo=',
    verifyBLMNo: '/Manifest/CheckBlmother?Blmother=',
    verifyContener: '/Manifest/CheckContendor?',
    verifyBL: '/Manifest/CheckBlNomber?',
    ship: '/Manifest/GetShip?name=',
    port: '/Manifest/GetPorts?name=',
    containerType: '/Manifest/GetContainerType',
    loadTypeandClient: '/Manifest/GetTypes',
    typeofMerchandise: '/Manifest/GetMerchandiseType',
    agencies: '/Manifest/GetAgencies',
    packingType: '/Manifest/GetPackagesType',
    city: '/Manifest/GetCities?name=',
    dangerType: '/Manifest/GetDangerType',
    saveParseXML: '/Manifest/ParseXmlManifest',
    uploadmanifestDoc: '/Files/UploadmanifestDocument',
    uploadmanifestDocInTable: '/Files/UpdateManifestDocumentEntry',
    saveManifest: '/Manifest/SaveManifiest',
    updateManifest: '/Manifest/UpdateManifiest',
    updateImpediment: '/bol/UpdateDmImpediment',
    serchManifest: '/Manifest/GetManifestList',
    getDocument: '/Files/GetByManifest?manifest=',
    getInvoice: '/Bill/GetQuotation?Id=',
    downloadDocument: '/Files/Download?id=',
    manifetDetailsById: '/Manifest/GetManifestById?manifestId=',
    verifyRNC: '/Manifest/GetClientByRnc',
    paymentRequest: '/Payment/GetSignedPaymentRequest',
    getAuthHash: '/Payment/GetAuthHash',
    getAzualPaymentResponse: '/Payment/AzulPaymentSuccess',
    calculateCIF: '/Bill/GetCalculateCIF',
    getQuotation: '/Bill/GetQuotation',
    getHistoryDetailsByTallyNo: '/BOL/GetHistoryDetailsByTallyNo?TallyNo=',
    getAllBlDetailsList: '/BOL/GetAllBillDetails',
    ReactiveManifest: '/BOL/ReactiveManifest',
    changeBlStatus: '/BOL/AddHistoryStatus',
    GetEstatusContainer: '/BOL/GetEstatusByContainer?CodeContainer=',
    GetFastAnswers: '/BOL/GetActiveFastAnswers',
    GetActiveFastAnswersContainer: '/BOL/GetActiveFastAnswersContainer',
    GetDataByIdentificadorContainer: '/BOL/GetDataByIdentificadorContainer?ContainerIdentifier=',
    InactiveFastAnswers: '/BOL/InactiveFastAnswers?descripcion=',
    AddFastAnswers: '/BOL/AddFastAnswer?descripcion=',
    AddContainerStatus: '/BOL/AddHistoricoEstatusContainerTHAsync?ContainerIdenfier=',
    menifestWis: '/Manifest/ManifestWis?manifestNo=',
    deleteFile: '/Files/Delete?Id=',
    blStatus: '/Manifest/GetStatus?AssociateStatusType=3',
    getClientCode: '/Manifest/GetClientCode',
    saveStatusinfo: '/Status/SaveStatus',
    getStatusinfo: '/Status/GetStatusByBLCode?BlCode=',
    getBLByManifest: '/BOL/GetBillByManifestNo?manifestNo=',
    getImpedimentsByDMDetail: '/BOL/GetImpedimentHistory?dmCodigoDetalle=',
    getHistoricStatusList: '/BOL/GetHistoricStatusList?CodigoRegistro=',
    getContainerStatusList: '/BOL/GetContainerStatusList?CodigoRegistro=',
    downloadProductTemplate: '/Product/ProductTemplate',
    saveProductExcel: '/Product/ProductExcel',
    parseProductExcel: '/Product/ParseProductExcel',
    parseEntryProductExcel: '/ReferenceReception/CheckEntradaProduct',
    parseDisptchProductExcel: '/Purchase/CheckDispatchProduct',
    getProductTemplateList: '/Product/ProductTemplates',
    sendInWISProduct: '/Product/ProductWis',
    getProductByTempateId: '/Product/Products',
    productToWis: '/Product/ProductToWis?productId=',
    downloadMasterTemplate: '/Products/DownloadProductList',
    saveDraft: '/WarehouseEntry/EntryInWarehouse',
    uploadWarehouseDocuments: '/WarehouseEntry/SaveMultipleDocument',
    getWarhouseDocument: '/WarehouseEntry/DocumentList',
    removeTransport: '/WarehouseEntry/RemoveTransport',
    removeDocumnet: '/WarehouseEntry/DeleteDocument',
    updateDraft: '/WarehouseEntry/UpdateWarehouseEntry',
    checkRefrenceNumber: '/WarehouseEntry/CheckReferenceNumber?ReferenceNumber=',
    checkDispatchRefrenceNumber: '/DraftDispatch/CheckDraftDispatchReferenceNumber?ReferenceNumber=',
    getDraftList: '/WarehouseEntry/WarehouseEntryList?EntryType=1',
    deleteDraft: '/WarehouseEntry/RemoveDraftEntry?id=',
    deleteDraftProduct: '/WarehouseEntry/RemoveDraftProduct?id=',
    deleteDispatchDraftProduct: '/DraftDispatch/RemoveDraftDispatchProduct?id=',
    getDispatchDraftList: '/DraftDispatch/GetDraftDispatch',
    saveDispatchDraft: '/DraftDispatch/SaveDraft',
    saveDraftDispatch: '/DraftDispatch/SaveDraftDispatch',
    updateDispatchDraft: '/DraftDispatch/UpdateDraftDispatch',
    deleteDispatchDraft: '/DraftDispatch/RemoveDraftDispatch?Id=',
    saveDraftProduct: '/WarehouseEntry/SaveWarehouseProduct',
    saveDispatchDraftProduct: '/DraftDispatch/SaveDraftDispatchProduct',
    updateDraftProduct: '/WarehouseEntry/UpdateDraftProduct',
    updateDispatchDraftProduct: '/DraftDispatch/UpdateDraftDispatchProduct',
    getDraftProduct: '/WarehouseEntry/DraftProductList',
    getDraftProductList: '/DraftDispatch/DraftDispatchProductList',
    deleteDarftProduct: '/DraftEntry/DeleteDraftProduct',
    downloadXML: '/Manifest/DownloadXml?manifestId=',
    getPaymentDetails: '/Payment/GetAzulPaymentData?TallyNo=',
    getConsolidator: '/Manifest/GetConsolidators?data=',
    saveConsolidator: '/Manifest/AddConsolidador',
    getConsolidatorList: '/Manifest/ConsolidadorList',
    getRefrenceNo: '/WarehouseEntry/AutoRfeReception',
    getDispatchRefrenceNo: '/DraftDispatch/DispatchAutoRfeReception',
    getWarehouseDataByType: '/WarehouseEntry/GetWarehouseEntryByType',
    getDispatchDataByType: '/DraftDispatch/GetDraftDispatchByType',
    saveAgent: '/Agents/SaveAgent',
    getAgent: '/Agents/GetAllAgents',
    productTemplateErrors: '/Product/ProductTemplateErrors?interfaceid=',
    getDriverList: '/PreEntry/DriverDetailList',
    getVehicleList: '/PreEntry/VehiclesList',
    saveDriver: '/PreEntry/SaveDriverDetail',
    saveVehicle: '/PreEntry/SaveVehiclesDetail',
    saveAgenda: '/PreEntry/SaveScheduleDetail',
    updateAgenda: '/PreEntry/EditScheduleDetail',
    getAgenda: '/PreEntry/ScheduleDetailListByRequestNo?',
    downloadInventeryProduct: '/ReferenceReception/DownloadStockList',
    downloadTemplate: '/ReferenceReception/DownloadTemplate?type=',
    getStockByClientId: '/ReferenceReception/getStockByIdClients?',
    getCountry: '/general-Information/country',
    getCompanyName: '/account-management/user/getCompaniesStartingWith?name=',
    industryType: '/general-Information/industrytype',
    personTitle: '/general-Information/personTitle',
    cityByCountryId: '/general-Information/country/{​​​​​​​{​​​​​​​countryId}​​​​​​​}​​​​​​​/cities',
    companyUserList:'/logistic-warehouse/User/GetUserlist', //FOR PROD ENV
    //companyUserList:`/${apiReferenceName}/User/GetUserlist`, //FOR qa ENV
    assignCompanyToUser:'/account-management/user/assignCompanyToUser',
    //SendMailToCompanyUser:'/logistic-warehouse/User/SendMailToCompanyUser', //FOR PROD ENV
    SendMailToCompanyUser:`/${apiReferenceName}/User/SendMailToCompanyUser`, //FOR qa ENV
    dispachValidation:'/Purchase/CheckReferenceNumber?referenceNumber=',
    getOrders:'/Order/orders/',
    getAllRefrences:'/OrderDetailTracking/getAllRefrences/?refrenceNo=',
    getTranspTerrestreDetils:'/OrderDetailTracking/getCamiencitoRequestDetails/?refrenceNo=',
    getTranspTerrestreDetilsTMS: '/OrderDetailTracking/getTmsRequestDetails/?refrenceNo=',
    getAllTarjasDetails:'/OrderDetailTracking/getAllTarjasDetails/?refrenceNos=',
    addToFavourite:'/Favourite/favourite/',
    getDetailsBlStatus:'/OrderDetailTracking/getConsolidatedTracking/?refrenceNo=',
    getFavouriteList:'/Favourite/FavouriteList/',
    getRecentServicesList:'/LastVisited/AuditLog/',
    downloadWindipDocumnet:'/OrderDetailTracking/downloadDocument?',
    getLastVisitedServices:'/LastVisited/LastVisitedServices?count=5',
    updateOrderImpediment:'/Order/setImpediments?',
    calificar: '/Order/calificar',
    ratingcount: '/Order/GetRatingsCount',
    getReferenceNumber:'/WarehouseEntry/GetReferenceNumber?referenceNumber=',
    ordersDownload:'/Order/ordersdownload/',
    loginurl:'/account-management/user/getToken',
    refreshToken:'/account-management/user/getTokenFromRefreshToken',
    //NotificationList:'/logistic-warehouse/ReferenceReception/NotificationList', //FOR PROD ENV 
    NotificationList:`/${apiReferenceName}/ReferenceReception/NotificationList`, //FOR qa ENV
    //SetSeenNotificationDetail:'/logistic-warehouse/ReferenceReception/SetSeenNotificationDetail', //FOR PROD ENV
    SetSeenNotificationDetail:`/${apiReferenceName}/ReferenceReception/SetSeenNotificationDetail`, //FOR qa ENV
    //UnreadNotificationList:'/logistic-warehouse/ReferenceReception/UnreadNotificationList', //FOR PROD ENV
    UnreadNotificationList:`/${apiReferenceName}/ReferenceReception/UnreadNotificationList`, //FOR qa ENV
    requestMail:'/Home/SendCreateSolisitudEmail',
    sentRequestNotification:'/ReferenceReception/sendWindipNotification',
    GetUserPreferenceList:'/ReferenceReception/GetUserPreferenceList',
    SaveUserPreference:'/ReferenceReception/SaveUserPreference',
    getAllClients:'/account-management/client/getbyname'
  },
  requesttypes: {
    transport: '90354f16-6872-48a7-3144-08d83a029f26',
    import: '785c41a7-5706-4743-3147-08d83a029f26',
    customs: '6985d287-8d9e-45b0-3145-08d83a029f26',
    voucher: '12b4d4f4-e552-4984-94dd-17a286c2024e'
  },
  lines: {
    geest: 'E6687B8F-3B18-455F-3F05-08D83A0273F8',
    baltic: '07696A60-C7A6-4B5F-3F07-08D83A0273F8',
    cosiarma: '1F0FC5FF-A94A-4643-3F06-08D83A0273F8',
    freightForward: '4F6E46D3-F526-486D-3F08-08D83A0273F8'
  },
  dummies: {
    cargoData: {
      useDummy: false,
      bls: ['S055676200650065', 'ECN0177413'],
      blSelected: 'S055676200650065',
      blData: {
        S055676200650065: {
          registeredDate: '2020-07-03T15:20:10',
          typedDate: '2020-07-07T14:59:19',
          declaredDate: '2020-07-09T16:44:15',
          verifiedDate: '2020-07-14T16:44:15',
          verifiedProcess: '2020-07-14T16:44:15',
          valuesSentForPayment: '2020-07-23T15:07:03',
          releasedDate: '2020-07-14T16:44:15',
          transitFinalDetinationDate: '2020-07-14T11:36:19',
          deliveryDate: '2020-07-14T16:09:12',
          declaredStatusDate: '2020-07-14T16:44:15',
          declaredStatus: 'Liberado por Aduanas',
          declaredSubStatus: 'En proceso de exoneracion',
          declaredDateSubStatus: '2020-07-28T16:28:25',
          blNumber: 'S055676200650065',
          documentNumber: '101886714',
          containerList: [
            {
              containerNumber: 'FCIU9430640',
              returnDate: '2020-07-14T11:36:19',
              retirementDate: '2020-07-14T08:00:00'
            },
            {
              containerNumber: 'SMLU7906632',
              returnDate: '2020-07-15T09:04:18',
              retirementDate: '2020-07-15T08:00:00'
            },
            {
              containerNumber: 'SMLU6415845',
              returnDate: '2020-07-15T09:04:18',
              retirementDate: '2020-07-15T08:00:00'
            },
            {
              containerNumber: 'SMLU6415845',
              returnDate: '2020-07-15T12:56:51',
              retirementDate: '2020-07-16T08:00:00'
            },
            {
              containerNumber: 'SMLU7906632',
              returnDate: '2020-07-15T12:56:51',
              retirementDate: '2020-07-16T08:00:00'
            },
            {
              containerNumber: 'FCIU9430640',
              returnDate: '2020-07-14T11:49:31',
              retirementDate: '2020-07-15T08:00:00'
            }
          ]
        },
        ECN0177413: {
          registeredDate: '2020-06-30T12:32:13',
          typedDate: '2020-07-01T10:21:07',
          declaredDate: '2020-07-28T10:29:06',
          verifiedDate: null,
          verifiedProcess: null,
          valuesSentForPayment: null,
          releasedDate: null,
          transitFinalDetinationDate: null,
          deliveryDate: null,
          declaredStatusDate: '2020-07-28T10:29:06',
          declaredStatus: 'Declarado',
          declaredSubStatus: null,
          declaredDateSubStatus: '2020-07-28T10:29:10',
          blNumber: 'ECN0177413',
          documentNumber: '101886714',
          containerList: []
        }
      }
    }
  }
};
export default serviceConfig;
